export default [
  {
    key: "report",
    name: "报表管理",
    icon: "bar-chart",
    submenus: [
      // { key: "batch_inventory", name: "库存明细" },
      { key: "warehouse_inventory", name: "库存报表" },
      { key: "stock_in_report", name: "入库报表" },
      { key: "stock_out_report", name: "出库报表" },
    ],
  },
  {
    key: "data",
    name: "基础数据",
    icon: "table",
    submenus: [
      // { key: "reservoir_area", name: "库区管理" },
      { key: "product", name: "产品信息" },
    ],
  },
  {
    key: "order",
    name: "订单管理",
    icon: "file",
    submenus: [
      { key: "stock_in_order_list", name: "入库订单" },
      { key: "stock_out_order_list", name: "出库订单" },
    ],
  },
];
